<template>
  <div class="toastification">
    <div class="d-flex align-items-start">
      <b-avatar
        variant="danger"
        size="1.8rem"
        class="mr-75 flex-shrink-0"
      >
        <feather-icon
          icon="AlertCircleIcon"
          size="15"
        />
      </b-avatar>
      <div class="d-flex flex-grow-1">
        <div>
          <h5 class="mb-0 font-weight-bolder toastification-title text-danger">
            Błędne dane logowania
          </h5>
          <small
            class="d-inline-block text-body"
          >Konto nie zostało aktywowane.
            <hr>
            <b-button
              block
              size="sm"
              @click="resendInvite"
            > Wyślij ponownie mail aktywacyjny </b-button>
          </small>
        </div>
        <span
          class="cursor-pointer toastification-close-icon ml-auto"
          @click="$emit('close-toast')"
        >
          <feather-icon
            icon="XIcon"
            class="text-body"
          />
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { BAvatar, BButton } from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'

export default {
  components: {
    BAvatar, BButton,
  },
  props: {
    email: { required: true, type: String },
  },
  methods: {
    resendInvite() {
      store.dispatch('user/resendInvite', { email: this.email })
        .then(response => {
          if (response && response.status === 201) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Zaproszenie wysłano ponownie',
                icon: 'MailIcon',
                variant: 'success',
              },
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Nie udało się wysłać zaproszenia.',
                icon: 'AlertCircleIcon',
                variant: 'danger',
              },
            })
          }
        })
      this.$emit('close-toast')
    },
  },
}
</script>

<style lang="scss" scoped>
.toastification-close-icon,
.toastification-title {
  line-height: 26px;
}

.toastification-title {
  color: inherit;
}
</style>
